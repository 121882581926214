<template>
  <div
    class="p-4 border-t border-white/10 flex w-full justify-between items-center font-['Inter']"
  >
    <h6 class="text-white text-4xl font-extrabold font-['Inter']">
      <a href="/">Slap Star</a>
    </h6>

    <div class="flex gap-40 max-lg:gap-10">
      <SocialLinks class="max-md:hidden" />
      <button class="button" @click="scrollToSubscribe">Subscribe</button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { socials } from '../config'
import SocialLinks from './SocialLinks.vue'

export default defineComponent({
  name: 'Header',
  components: { SocialLinks },
  setup() {
    const currentYear = new Date().getFullYear()

    return {
      socials,
      currentYear,
    }
  },
  methods: {
    scrollToSubscribe() {
      const subscribeSection = document.getElementById('subscribe')
      if (subscribeSection) {
        subscribeSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
  },
})
</script>
