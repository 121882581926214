<template>
  <div
    class="p-4 border-t border-white/10 flex w-full justify-between items-center font-['Inter']"
  >
    <h6
      class="text-white text-4xl font-extrabold font-['Inter'] max-md:text-xl"
    >
      <a href="/">Slap Star</a>
    </h6>

    <SocialLinks />
    <div class="text-white text-xl">
      {{ currentYear }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import SocialLinks from './SocialLinks.vue'

export default defineComponent({
  name: 'Footer',
  components: { SocialLinks },
  setup() {
    const currentYear = new Date().getFullYear()

    return {
      currentYear,
    }
  },
})
</script>
