import { SocialIcons } from '../types'

export const tgAppLink = 'https://t.me'
export const socials: SocialIcons[] = [
  {
    name: 'Instagram',
    src: '/icons/instagram.svg',
    link: 'https://instagram.com',
  },
  {
    name: 'X (Twitter)',
    src: '/icons/twitter.svg',
    link: 'https://twitter.com',
  },
  {
    name: 'Facebook',
    src: '/icons/facebook.svg',
    link: 'https://facebook.com',
  },
  {
    name: 'Telegram',
    src: '/icons/telegram.svg',
    link: 'https://t.me',
  },
]
