<template>
  <div class="grid grid-cols-2 gap-x-5 gap-y-8 items-start">
    <div
      v-for="attr in attributes"
      :key="attr.label"
      class="flex flex-col grow shrink justify-center p-2.5 rounded-2xl bg-white/10 border border-white/10 max-md:text-xs backdrop-blur-[51.21px]"
    >
      <div class="font-semibold leading-none text-white">
        {{ attr.label }}
      </div>
      <div class="leading-9 text-white text-opacity-70">
        {{ attr.rarity }}<br />Floor: {{ attr.floor }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { NFTAttribute } from '../types'

export default defineComponent({
  name: 'NFTAttributes',
  setup() {
    const attributes: NFTAttribute[] = [
      {
        label: 'BACKGROUND',
        rarity: 'Bright light 8%',
        floor: '0,0146 ETH',
      },
      {
        label: 'BROWS',
        rarity: '8%',
        floor: '0,0146 ETH',
      },
      {
        label: 'EYE COLOR',
        rarity: 'Brown Olive 4%',
        floor: '0,0146 ETH',
      },
      {
        label: 'DOMINANT COLOR',
        rarity: '#FEAC15 0.79%',
        floor: '0,0146 ETH',
      },
      {
        label: 'BLUSH COLOR',
        rarity: 'Pink 23 8%',
        floor: '0,0146 ETH',
      },
      {
        label: 'NOSE',
        rarity: 'A2 6%',
        floor: '0,0146 ETH',
      },
      {
        label: 'CATEGORY',
        rarity: 'Flower Girl 96%',
        floor: '0,0146 ETH',
      },
      {
        label: 'FACIAL FEATURES',
        rarity: 'Beauty Mark 11%',
        floor: '0,0146 ETH',
      },
    ]

    return {
      attributes,
    }
  },
})
</script>
