<template>
  <div class="flex items-center gap-6 max-md:gap-3">
    <a
      v-for="icon in socials"
      :key="icon.name"
      :href="icon.link"
      target="_blank"
      rel="noopener noreferrer"
      :aria-label="icon.name"
      class="w-6 h-6 transform transition-transform duration-300 hover:scale-110"
    >
      <img :src="icon.src" :alt="icon.name" class="w-full h-full" />
    </a>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { socials } from '../config'

export default defineComponent({
  name: 'SocialLinks',
  setup() {
    return {
      socials,
    }
  },
})
</script>
