<template>
  <div
    class="flex flex-col items-center gap-32 max-md:gap-10 max-md:max-w-full"
  >
    <LandingBlock />

    <div class="flex flex-col max-w-full text-center w-[752px] max-md:px-6">
      <h2
        class="text-5xl max-md:text-xl font-medium leading-none text-white max-md:max-w-full"
      >
        Meet Slap
      </h2>
      <p
        class="mt-10 text-xl leading-8 text-white/60 max-md:text-base max-md:max-w-full"
      >
        Slap Star is a Play-to-Earn (P2E) Web3 game combining clicker mechanics,
        idle gameplay, and NFT collecting.<br /><br />
        Players collect unique NFTs "girls" who mine the in-game currency,
        $Slap. To withdraw $Slap, players switch characters to "slap" positions
        and tap specific areas to release the currency.
      </p>
    </div>

    <div class="flex flex-col max-w-full w-[1264px] max-md:px-6">
      <div
        class="flex overflow-hidden relative flex-col justify-center items-center px-20 py-14 w-full min-h-[716px] max-md:min-h-0 max-md:px-5 max-md:max-w-full"
      >
        <img
          loading="lazy"
          src="/meet_slap_bg.webp"
          alt=""
          class="object-cover absolute inset-0 size-full rounded-2xl border border-white/5"
        />
        <div class="relative flex items-center gap-5">
          <img
            loading="lazy"
            src="/meet_slap_girls.webp"
            alt="Meet girls page"
            class="w-[244px] max-md:w-[84px] rounded-xl overflow-hidden"
          />
          <img
            loading="lazy"
            src="/meet_slap_home.webp"
            alt="Meet home page"
            class="w-[279px] max-md:w-[94px] rounded-xl overflow-hidden"
          />
          <img
            loading="lazy"
            src="/meet_slap_upgrade.webp"
            alt="Meet upgrade page"
            class="w-[244px] max-md:w-[84px] rounded-xl overflow-hidden"
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col max-w-full w-[1520px] max-md:px-6">
      <div class="flex flex-col w-full text-center max-md:max-w-full">
        <div class="flex flex-col w-full max-md:max-w-full">
          <div
            class="flex flex-col items-center text-center self-center max-w-full w-[752px]"
          >
            <h2
              class="text-4xl font-medium leading-none text-white max-md:text-xl"
            >
              Gameplay mechanics
            </h2>
            <p class="mt-5 text-xl leading-8 text-white/60 max-md:text-base">
              The gameplay mechanics of "Slap Star" are based on collecting and
              interacting with NFT-based characters (girls).
            </p>
          </div>

          <GameplayFeatures />
        </div>
      </div>

      <div class="flex flex-col self-center mt-10 max-w-full w-[1264px]">
        <div
          class="flex overflow-hidden relative flex-col justify-center items-center px-20 py-12 w-full max-md:min-h-0 max-md:px-4 max-md:py-9 max-md:max-w-full"
        >
          <img
            loading="lazy"
            src="/meet_slap_bg.webp"
            alt=""
            class="z-0 object-cover absolute inset-0 size-full rounded-2xl border border-white/5"
          />
          <div
            class="flex justify-between items-center gap-5 relative max-md:flex-col max-md:items-start"
          >
            <div class="flex flex-col">
              <h3 class="text-4xl max-md:text-xl text-white/60">
                Future features
              </h3>
              <ul
                class="mt-4 text-2xl max-md:text-base text-white list-disc leading-[54px]"
              >
                <li><span class="font-semibold">AI avatars</span></li>
                <li><span class="font-semibold">Mini-Games</span></li>
                <li>
                  <span class="font-semibold">Customization Options</span>
                </li>
              </ul>
              <p class="mt-5 text-4xl max-md:text-xl text-white/60">
                And more to come
              </p>
            </div>
            <div class="flex items-end gap-5 max-md:order-[-1]">
              <img
                loading="lazy"
                src="/gameplay_lootbox.webp"
                alt="Gameplay lootbox"
                class="w-[286px] max-md:w-[155px] rounded-xl overflow-hidden"
              />
              <img
                loading="lazy"
                src="/gameplay_main.webp"
                alt="Gameplay home page"
                class="w-[250px] max-md:w-[136px] rounded-xl overflow-hidden"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center max-w-full w-[1264px] px-6">
      <div class="flex flex-col gap-5 text-center max-w-[752px]">
        <h2 class="text-4xl max-md:text-xl text-white">NFT Girls</h2>
        <p class="text-xl leading-8 max-md:text-base text-white/60">
          Each girl has parameters like the speed at which she generates $Slap
          and the maximum $Slap she can accumulate.
        </p>
      </div>

      <div
        class="flex flex-col self-stretch mt-10 w-full text-2xl max-md:max-w-full"
      >
        <div
          class="flex overflow-hidden relative flex-col justify-center items-center px-16 py-14 w-full min-h-[716px] max-md:px-5 max-md:max-w-full"
        >
          <img
            loading="lazy"
            src="/meet_slap_bg.webp"
            alt=""
            class="z-0 object-cover absolute inset-0 size-full rounded-2xl border border-white/5"
          />
          <div
            class="flex relative gap-10 items-center max-lg:flex-col max-lg:items-stretch"
          >
            <NFTAttributes class="grow" />

            <div
              class="flex items-center pl-[150px] max-md:pl-[88px] relative max-md:self-center max-lg:order-[-1]"
            >
              <img
                loading="lazy"
                src="/nft_girl_card.webp"
                alt="NFT girl card"
                class="left-0 top-[106px] absolute z-10 w-[262px] max-md:w-[168px] max-md:top-[80px] max-md: rounded-xl"
              />
              <img
                loading="lazy"
                src="/nft_girls.webp"
                alt="Nft girls page"
                class="w-[279px] opacity-50 max-md:w-[178px] rounded-xl overflow-hidden"
              />
            </div>
          </div>
        </div>
      </div>

      <p
        class="mt-10 text-xl leading-8 text-center text-white/60 w-[752px] max-md:text-base max-md:max-w-full"
      >
        Girls are classified by grades (or tiers), which determine their
        effectiveness and rarity, boosting their utility and price
      </p>

      <NFTGrades />
    </div>

    <div
      class="flex flex-col items-center gap-10 max-w-full w-[1520px] max-md:px-6"
    >
      <div
        class="flex flex-col items-center text-center self-center max-w-full w-[752px]"
      >
        <h2 class="text-4xl font-medium leading-none text-white max-md:text-xl">
          Win-win partnerships with creators
        </h2>
        <p class="mt-5 text-xl leading-8 text-white/60 max-md:text-base">
          Models will be able to create personalized NFT collections, earning a
          share of purchases and direct donations from fans.
        </p>
      </div>

      <div
        class="relative flex flex-col gap-6 items-center w-[817px] max-w-full"
      >
        <img
          loading="lazy"
          src="/icons/red_arrow.svg"
          alt="Left arrow"
          class="absolute left-5 top-36 max-md:top-16 max-md:h-2/3"
        />
        <img
          loading="lazy"
          src="/icons/blue_arrow.svg"
          alt="Right arrow"
          class="absolute right-5 top-36 max-md:top-16 max-md:h-2/3"
        />
        <img
          loading="lazy"
          src="/model.webp"
          alt="Model"
          class="w-72 max-md:w-28 rounded-full overflow-hidden"
        />
        <p class="z-10 text-white text-4xl max-md:text-xl font-semibold">
          Model
        </p>

        <ul
          class="z-10 text-4xl text-white flex flex-col gap-3 list-disc max-md:text-sm"
        >
          <li>New revenue streams for creators</li>
          <li>User base exchange</li>
          <li>Enhanced fan experiences</li>
          <li>Entry into the Web3 and AI</li>
          <li>Greater audience engagement</li>
        </ul>

        <img
          loading="lazy"
          src="/icons/icon-coin.webp"
          alt="Coin"
          class="p-7 rounded-full bg-white/5 w-72 max-md:w-28 overflow-hidden"
        />
      </div>

      <div class="mt-6 max-md:px-6 w-full flex justify-center">
        <Subscribe />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from './components/Header.vue'
import GameplayFeatures from './components/GameplayFeatures.vue'
import NFTAttributes from './components/NFTAttributes.vue'
import NFTGrades from './components/NFTGrades.vue'
import Footer from './components/Footer.vue'
import Subscribe from './components/Subscribe.vue'
import LandingBlock from './components/LandingBlock.vue'

export default defineComponent({
  name: 'SlapLanding',
  components: {
    LandingBlock,
    Subscribe,
    GameplayFeatures,
    NFTAttributes,
    NFTGrades,
    Header,
    Footer,
  },
})
</script>
