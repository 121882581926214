<template>
  <div
    class="grid grid-cols-4 max-md:grid-cols-2 gap-10 max-md:gap-4 items-center mt-10 max-md:max-w-full"
  >
    <div v-for="grade in grades" :key="grade.alt" class="max-w-60">
      <img
        :src="grade.image"
        :alt="grade.alt"
        loading="lazy"
        class="object-contain w-full"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { NFTGrade } from '../types'

export default defineComponent({
  name: 'NFTGrades',
  setup() {
    const grades: NFTGrade[] = [
      {
        image: '/nft_girl_common.webp',
        alt: 'NFT Common',
      },
      {
        image: '/nft_girl_rare.webp',
        alt: 'NFT Rare',
      },
      {
        image: '/nft_girl_epic.webp',
        alt: 'NFT Epic',
      },
      {
        image: '/nft_girl_legendary.webp',
        alt: 'NFT Legendary',
      },
    ]

    return {
      grades,
    }
  },
})
</script>
