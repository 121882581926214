<template>
  <div
    class="relative w-full h-[100vh] flex flex-col items-center overflow-hidden bg-[url('/background.webp')] bg-no-repeat bg-cover"
  >
    <Header />

    <div
      class="relative flex justify-between items-center px-10 w-full max-w-[1280px] grow max-md:items-end py-20"
    >
      <div class="flex flex-col justify-center gap-10 z-10">
        <div>
          <h1
            class="text-white text-9xl leading-tight font-black max-md:text-6xl"
          >
            Slap Star
          </h1>
          <p
            class="text-white text-4xl max-md:text-xl leading-tight font-medium"
          >
            Play-to-Earn Web3 game
          </p>
        </div>
        <div class="flex gap-4 max-md:flex-col">
          <a
            :href="tgAppLink"
            target="_blank"
            rel="noopener noreferrer"
            class="w-60 h-16 max-md:h-12 bg-gradient-to-tr from-[#dd0a6f] to-[#ff5b70] button cursor-pointer"
          >
            <img
              src="/icons/telegram.svg"
              alt="Telegram Icon"
              class="w-6 h-6 mr-2"
            />
            Try Now
          </a>
          <button
            @click="scrollToSubscribe"
            class="w-60 h-16 max-md:h-12 button"
          >
            Subscribe
          </button>
        </div>
      </div>

      <div
        class="flex absolute right-0 z-0 top-16 [transform:rotateY(180deg)] justify-center overflow-hidden items-start"
      >
        <img
          class="w-[592px] h-[1431px] max-md:w-auto max-md:h-auto"
          src="/marusia-standard.webp"
          alt="Character"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import { tgAppLink } from '../config'

export default {
  name: 'LandingBlock',
  components: { Header },
  setup() {
    return {
      tgAppLink,
    }
  },
  methods: {
    scrollToSubscribe() {
      const subscribeSection = document.getElementById('subscribe')
      if (subscribeSection) {
        subscribeSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
  },
}
</script>
